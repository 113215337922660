<template>
  <div class="image-with-text">
    <img :src="imageSrc" alt="Image" />
    <div class="tit">{{ tit }}</div>
    <div class="tit1">{{ tit1 }}</div>
  </div>
</template>

<script>
export default {
  name: 'ImageWithText',
  props: {
    imageSrc: String, 
    tit:String,
    tit1:String,
  },
}
</script>

<style scoped>
.image-with-text {
  position: relative;
  display: flex; 
  justify-content: center; 
  width: 100%; /* 根据需要调整图片的显示宽度 */
  align-items: center; 
  margin: auto;
}

.image-with-text img {
  width: 100%; /* 使图片填满容器 */
  height: auto; /* 保持图片比例 */
  
}

.tit {
  position: absolute;
  bottom: 0; /* 定位到图片的底部 */
  left: 50%; /* 将文字内容定位到容器的中心 */
  transform: translate(-50%, 50%); /* 向上移动自身高度的50%，同时居中对齐 */
  background-color: rgb(8, 72, 83); /* 为了突出显示，可以添加半透明背景 */
  padding: 30px; /* 文字周围的空间 */
  color:#fff;
  width: 100%;
}
@media (max-width: 1000px) {
.image-with-text {
  position: relative;
  display: flex; 
  justify-content: center; 
  width: 100%; /* 根据需要调整图片的显示宽度 */
  align-items: center; 
  margin: auto;
}

.image-with-text img {
  width: 100%; /* 使图片填满容器 */
  height: auto; /* 保持图片比例 */
  
}

.tit {
  position: absolute;
  bottom: 0; /* 定位到图片的底部 */
  left: 50%; /* 将文字内容定位到容器的中心 */
  transform: translate(-50%, 70%); /* 向上移动自身高度的50%，同时居中对齐 */
  background-color: rgb(8, 72, 83); /* 为了突出显示，可以添加半透明背景 */
  padding: 10px 30px; /* 文字周围的空间 */
 

  font-size: 10px;
  width: 100%;
}



}

</style>
