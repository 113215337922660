<template>
  <div class="time-module">
    <span class="market-status">{{ marketStatus }}</span>
    <span class="current-time">{{ currentTime }}</span>
  </div>
</template>

<script>
export default {
  name: 'LocalTime',
  data() {
    return {
      currentTime: this.getKoreanTime().toLocaleTimeString('ko-KR'),
    };
  },
  computed: {
    marketStatus() {
      const now = this.getKoreanTime();
      const dayOfWeek = now.getDay(); // 获取当前是周几，0表示周日，1表示周一
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const time = hours * 100 + minutes; // 转换为HHMM格式

      // 检查是否在周六或周日
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return '주식 시장이 휴장 중입니다';
      }

      // 检查是否在开盘时间内
      if ((time >= 930 && time <= 1130) || (time >= 1300 && time <= 1500)) {
        return '주식시장 개장중';
      } else {
        return '주식 시장이 휴장 중입니다';
      }
    },
  },
  created() {
    this.updateTime();
  },
  methods: {
    updateTime() {
      setInterval(() => {
        this.currentTime = this.getKoreanTime().toLocaleTimeString('ko-KR');
      }, 1000);
    },
    getKoreanTime() {
      // 创建一个表示当前时间的Date对象
      const now = new Date();
      // 将当前时间转换为韩国时区的时间
      const koreanTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
      return koreanTime;
    },
  },
};
</script>


  
  <style scoped>
  .time-module {
  background-color: white; /* 设置模块背景色为白色 */
  color: #b9b9b9; 
  padding: 10px; /* 根据需要添加内边距 */
  border-radius: 5px; /* 如需要，可以添加圆角 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  
}

.market-status, .current-time {
  margin: 0 20px; 
 
}
  </style>
  