<template>
    <button v-if="isVisible" @click="scrollToTop" class="back-to-top">↑</button>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
      };
    },
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      checkVisibility() {
        this.isVisible = window.scrollY > 300;
      },
    },
    mounted() {
      window.addEventListener('scroll', this.checkVisibility);
    },
    unmounted() {
      window.removeEventListener('scroll', this.checkVisibility);
    },
  };
  </script>
  
  <style scoped>
  .back-to-top {
   
    border-radius: 25px; 
    width: 60px; /* 按钮宽度 */
  height: 30px; /* 按钮高度 */
  border: none; /* 移除边框 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    /* 看我多贴心 那么多代码基本全写注释了 */
   
  }
  </style>
  