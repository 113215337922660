<template>
    <div class="video-container">
      <video
        controls
        src="./KM0220.mp4"
        poster="./KM0220.png"
        class="video-player"
      ></video>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoPlayer',
  };
  </script>
  
  <style scoped>
  .video-container {
    width: 100%; 
    margin: 0;
   
    height: 100%; 
  }
  
  .video-player {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
  }
  </style>
  