<template>
  <br>
<DownloadApp></DownloadApp>
<br>                    
<div class="content-module1">
    <h1 class="tit1">KM  technology incice</h1>
      <slot>회사의 이념은 개척, 진취, 정직, 효율이며, 회사의 방침은 영구적인 추구와 우수한 품질입니다. </slot>
    </div>


    <div class="content-module">
      <slot>© 2024 KM  technology incice.</slot>
    </div>
  </template>
  
  <script>
  import DownloadApp from './AppDownload.vue';
  export default {
    name: 'SimpleContentModule',
    components: {
    DownloadApp 
  }
  }
  </script>
  
  <style scoped>


.content-module1 {
    background-color: #128ca1;
    color: white; 
    padding: 20px;
    text-align: center;
   height: 200px;
  }
  .content-module {
    background-color: #1faab4; 
    color: white; 
    padding: 20px; 
    text-align: center; 
   
  }
  </style>
  