<template>
  
  <div>

  <nav class="top-barS" :class="{'nav-open': isNavOpen}">

   <router-link to="/">
    <img src="../images/logo.png" alt="Logo" class="logoPH">
  </router-link>
   
    

    
  <button class="menu-button" :class="{'open': isNavOpen}" @click="toggleNav">
<span class="menu-line"></span>
<span class="menu-line"></span>
<span class="menu-line"></span>
</button>




  </nav>


  <div class="side-nav1" :class="{ 'open': isNavOpen }">
<div class="nav-links-container">

<h3 class="nav-linkS" @click="toggleSubMenu('menu')">
  <span class="link-title">회사소개</span>

  <div class="icon-container" :class="{'rotate': subMenuStates.menu}">
<ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div>


</h3>
<div v-if="subMenuStates.menu" class="submenu">
  <a href="https://mobile.kmteco.top" class="nav-link" @click="closeNav">외자기관 VIP 전용</a>

  <router-link to="/aboutus" class="nav-link" @click="closeNav">회사소개</router-link>
  <router-link to="/JeF1" class="nav-link" @click="closeNav">투자 은행</router-link>
<router-link to="/JeF2" class="nav-link" @click="closeNav">공공 재정</router-link>
<router-link to="/JeF3" class="nav-link" @click="closeNav">연구</router-link>
<router-link to="/JeF4" class="nav-link" @click="closeNav">고정 수입</router-link>
<router-link to="/JeF5" class="nav-link" @click="closeNav">대체 자산 관리</router-link>
<router-link to="/JeF6" class="nav-link" @click="closeNav">주식</router-link>

   
</div>
<!-- <hr style="width: 100%; border-color: rgb(231, 231, 231);"> -->


<h3 class="nav-linkS" @click="toggleSubMenu('menu1')">
  <span class="link-title">국내외 증시</span>
  <div class="icon-container" :class="{'rotate': subMenuStates.menu1}">
<ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
<div v-if="subMenuStates.menu1" class="submenu">
  <router-link to="/PageData3" class="nav-link" @click="closeNav">국내 증시</router-link>
  <router-link to="/PageData2" class="nav-link" @click="closeNav">해외 증시</router-link>
</div>


<h3 class="nav-linkS" @click="toggleSubMenu('menu2')">
  <span class="link-title">실시간 시황</span>
  <div class="icon-container" :class="{'rotate': subMenuStates.menu2}">
<ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div>  </h3>
<div v-if="subMenuStates.menu2" class="submenu">
  <router-link to="/PageData4" class="nav-link" @click="closeNav">실시간 시황</router-link>
</div>


<h3 class="nav-linkS" @click="toggleSubMenu('menu3')">
  <span class="link-title">종목체크</span>
  <div class="icon-container" :class="{'rotate': subMenuStates.menu3}">
<ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
<div v-if="subMenuStates.menu3" class="submenu">
  <router-link to="/PageData5" class="nav-link" @click="closeNav">종목종합소식</router-link>
  <router-link to="/PageData6" class="nav-link" @click="closeNav">상승종목</router-link>
  <router-link to="/PageData7" class="nav-link" @click="closeNav">급등종목</router-link>
  <router-link to="/PageData8" class="nav-link" @click="closeNav">상한가 종목</router-link>
  <router-link to="/PageData9" class="nav-link" @click="closeNav">거래량상위종목</router-link>
</div>


<h3 class="nav-linkS" @click="toggleSubMenu('menu4')">
  <span class="link-title">시장지표</span>
  <div class="icon-container" :class="{'rotate': subMenuStates.menu4}">
<ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
<div v-if="subMenuStates.menu4" class="submenu">
  <router-link to="/PageData10" class="nav-link" @click="closeNav">전종목시세</router-link>
  <router-link to="/PageData11" class="nav-link" @click="closeNav">투자주체별 동향</router-link>
  <router-link to="/PageData12" class="nav-link" @click="closeNav">외국인매매동향</router-link>
  <router-link to="/PageData13" class="nav-link" @click="closeNav">외환</router-link>
  <router-link to="/PageData14" class="nav-link" @click="closeNav">채권/금리</router-link>
  <router-link to="/PageData15" class="nav-link" @click="closeNav">국내경제지표</router-link>
</div>

<h3 class="nav-linkS" @click="toggleSubMenu('menu5')">
  <span class="link-title">2024 소식지</span>
  <div class="icon-container" :class="{'rotate': subMenuStates.menu5}">
<ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div>  </h3>
<div v-if="subMenuStates.menu5" class="submenu">
  <router-link to="/PageData16" class="nav-link" @click="closeNav">데스크 칼럼</router-link>
  <router-link to="/PageData17" class="nav-link" @click="closeNav">현장 리포터</router-link>
  <router-link to="/PageData18" class="nav-link" @click="closeNav">은행은 지금</router-link>
</div>


<h3 class="nav-linkS" @click="toggleSubMenu('menu6')">
  <span class="link-title">최신 뉴스</span>
  <div class="icon-container" :class="{'rotate': subMenuStates.menu6}">
<ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
<div v-if="subMenuStates.menu6" class="submenu">
  <router-link to="/PageData19" class="nav-link" @click="closeNav">최신 뉴스</router-link>
</div>
</div>
</div>











 </div>







  <div class="content">
    
    <router-view></router-view> 
  </div>






<div>
  
  <BackToTopButton />
</div>
<BottomNav></BottomNav>
<FloatingButton />
</template>


<script>
import BackToTopButton from '../components/BackToTopButton.vue';
import BottomNav from './BottomNav.vue';
import FloatingButton from '@/components/FloatingButton.vue';
export default {
name: 'App',
components: {
BackToTopButton,
BottomNav,
FloatingButton
}, 







data() {
return {

  subMenuStates: {
    menu: false, 
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
   

  },

  isNavOpen: false,

};
},
methods: {

toggleSubMenu(menuItem) {
  Object.keys(this.subMenuStates).forEach((key) => {
  this.subMenuStates[key] = false;
});


  this.subMenuStates[menuItem] = !this.subMenuStates[menuItem];
},
closeNav() {
this.isNavOpen = false;
document.body.classList.remove('no-scroll');
},
toggleNav() {
  this.isNavOpen = !this.isNavOpen;

}, 


}
};



</script>

<style scoped>
 .nav-link {
  font-size: 1px;
  display: block;
  color: #ffffff;
  padding: 10px 20px; 
  text-decoration: none;
  width: 100%; 
  box-sizing: border-box;
  margin: 0; 
  transition: color 0.3s ease;
  /* border-bottom: 1px solid #cccccc;  */

   transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
  transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
  
 }


 .nav-link:hover {
  color: rgb(170, 142, 82); 
  /* font-size: larger; */
  transition:color 0.8s ease; /* 添加平滑的过渡效果 */
  
  text-decoration: underline;
}


 /* 导航链接样式 */
 .nav-linkS {

  
 
display: block;
color: #ffffff;
padding: 10px 20px; 
text-decoration: none;
width: 100%; 
box-sizing: border-box;
margin: 0; 
transition: color 0.3s ease;

/* border-bottom: 1px solid #cccccc;  */

transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */

}


.nav-linkS:hover {
  color: rgb(170, 142, 82); 
  /* font-size: larger; */
  transition:color 0.6s ease; /* 添加平滑的过渡效果 */
  text-decoration: underline;
cursor: pointer;

}




.nav-linkS {
display: flex; /* 使用flex布局 */
justify-content: space-between; 
align-items: center; /* 垂直居中 */
width: 100%; /* 确保占满整个容器宽度 */
}

.link-title {

text-align: left; /* 文本靠左对齐 */
}

.icon-right {

margin-left: auto; /* 将图标推向右边 */
}

.submenu {
display: flex; 
justify-content: center; 
width: 100%; 
flex-direction: column;


}

.nav-link {
text-align: center; 
font-size: 20px;
color: #ffffff;
}





.side-nav1 {
position: fixed; 
top: 0; 
left: 0;
width: 100%; 
height: 100%;
transform: translateY(-100%); 
transition: transform 0.3s ease; 
z-index: 1000; 

background-color: rgb(15, 49, 82);
}


.side-nav1.open {
transform: translateY(0);
}


.top-barS {
z-index: 1001;
display: flex;
  justify-content: flex-end; 
  /* justify-content: space-between;  */
  align-items: center; /* 垂直居中对齐 */
  
 
  
background-color: white;

   position: relative;
   width: 100vw;
   height: 60px;
}







.logoPH {
  
display: block; 
max-width: 150px;  
max-height: 50px;  
height: auto;   
left: 3%;
position: absolute;
top: 0px;
}





.icon-container {
display: inline-block;
transition: transform 0.5s ease;
}

.rotate {
transform: rotate(180deg);
}

.menu-button {
display: flex;
flex-direction: column;
justify-content: space-around;
right: 10%;
width: 40px; /* 调整宽度 */
height: 30px; /* 调整高度 */
background: none;
border: none;
cursor: pointer;
padding: 0;
box-sizing: border-box;
z-index: 3000;
margin-right: 2%
}

.menu-line {
display: block;
width: 80%;
height: 2px; 
background: black; 
transition: all 0.3s ease;

}
.menu-line:hover {

  background: rgb(170, 142, 82); 
  transition: all 0.3s ease;
}

.menu-button.open .menu-line {
  background: white; /* 点击后变为白色 */
}
.top-barS.nav-open {
  background-color: rgb(15, 49, 82); /* 或你希望的蓝色 */
}

.menu-button.open .menu-line:nth-child(1) {
transform: translateY(10px) rotate(45deg);

}

.menu-button.open .menu-line:nth-child(2) {
opacity: 0;
}

.menu-button.open .menu-line:nth-child(3) {
transform: translateY(-10px) rotate(-45deg);
}

</style>
