<template>
    <div class="app-download-container">
        <h1 class="fff1">KM App 다운로드</h1>
        <br>
        
      <div class="button-container">
        
        <a href="https://apps.apple.com/cn/app/km-teco/id6478281324" class="download-button ios">
        <font-awesome-icon :icon="['fab', 'apple']" />  iOS
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.lmtejcolite.app" class="download-button android">
        <font-awesome-icon :icon="['fab', 'android']" />  Android
      </a> 
      <a href="/__UNI__95A30DA_0305192458.apk" class="download-button android" download="__UNI__95A30DA_0305192458.apk">
                <font-awesome-icon :icon="['fas', 'download']" /> APK
            </a>
    


    </div>
       <!-- 二维码下载模块 -->
 <div class="qr-code-container">
                <img src="/kmapp.png" alt="Download App QR Code" class="qr-code">
                <p style="color: #fff;">앱을 다운로드하려면 QR 코드를 스캔하세요</p>
            </div>
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'DownloadApp',
    // 组件逻辑
  }
  </script>
  
  <style scoped>
  .qr-code-container {
    margin-top: 20px; /* 调整间距 */
    text-align: center; /* 文本居中 */
}

.qr-code {
    width: 150px; /* 或根据需要调整二维码的尺寸 */
    height: auto; /* 保持宽高比 */
}
  .app-download-container {
    background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
    padding: 20px;
    text-align: center;
    margin: auto; /* 自动边距以居中 */
  max-width: 80%; /* 或者一个具体的宽度，如 600px */
  margin-left: 10%; /* 左边距 */
  margin-right: 10%; /* 右边距 */
  border-radius: 15px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 400px;
  }
  
  .download-button {
    display: flex; /* 使用 Flex 布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */

    padding: 10px 30px;
    color: white; /* 白色文字 */
    text-decoration: none; /* 去除下划线 */
    border: 1px solid white; /* 白色边框 */
    border-radius: 5px; /* 轻微圆角 */
    transition: background-color 0.3s, color 0.3s; /* 平滑过渡效果 */
  }
  .download-button .fa-icon {
    margin-right: 10px; /* 设置图标的右边距 */
  }
  .download-button font-awesome-icon {
    margin-right: 10px; /* 设置图标的右边距 */
  }
  
  .download-button.ios:hover {
    background-color: white;
    color: black;
  }
  
  .download-button.android:hover {
    background-color: white;
    color: black;
  }
  .fff1{
    color: #fff;
  }
  </style>
  