<template>
    <div class="image-with-text">
      <img :src="imageSrc" alt="Image" />
      <div class="tit">{{ tit }}</div>
      <div class="tit1">{{ tit1 }}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageWithText',
    props: {
      imageSrc: String, 
      tit:String,
      tit1:String,
    },
  }
  </script>
  
  <style scoped>
  .image-with-text {
    position: relative;
    display: flex; 
    justify-content: center; 
    width: 100%; /* 根据需要调整图片的显示宽度 */
    align-items: center; 
   
  }
  
  .image-with-text img {
    width: 100%; /* 使图片填满容器 */
    height: auto; /* 保持图片比例 */
    
  }
  .image-with-text::after {
  content: ''; /* 必须设置，即使没有内容 */
  position: absolute;
  bottom: 0; /* 定位到容器底部 */
  left: 0;
  width: 100%; /* 与容器同宽 */
  height: 30px; /* 滤镜的高度 */
  background-color: #0a6379bb; /* 半透明蓝色 */
  z-index: 2; /* 确保它位于图片之上 */
}
  
  
  </style>
  