<template>
    <div :class="`content-module ${moduleType}`">
       
       
        <img v-if="imageSrc" :src="imageSrc" alt="Module Image" class="module-image">
        <p v-if="titlemin">{{ titlemin }}</p>


       <h1 v-if="title">{{ title }}</h1>
       <h2 v-if="title1">{{ title1 }}</h2>
       <h2 v-if="title2">{{ title2 }}</h2>
       <h3 v-if="title3">{{ title3 }}</h3>

       
      <p v-if="description">{{ description }}</p>
      <p v-if="description1">{{ description1 }}</p>
      <p v-if="description2">{{ description2 }}</p>
      <p v-if="description3">{{ description3 }}</p>


    <ul v-if="processedListItems.length">
  <li v-for="(item, index) in processedListItems" :key="index">{{ item }}</li>
</ul>

    

      <p v-if="description4">{{ description4 }}</p>
      <h2 v-if="description5">{{ description5 }}</h2>
      <h2 v-if="description6">{{ description6 }}</h2>



      <hr v-if="description6" class="custom-line" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContentModule',
    props: {
      type: {
        type: String,
        default: 'blue',
      },
      titlemin: String,
      title: String,
      title1: String,
      title2: String,
      title3: String,
      description: String,
      description1: String,
      description2: String,
      description3: String,
      description4: String,
      description5: String,
      description6: String,
      imageSrc: String,
      listItems: {
      type: [Array, String], 
      default: () => [],
      },
    },
    computed: {
      moduleType() {
        return this.type === 'blue' ? 'blue-background' : 
               this.type === 'white' ? 'white-background' : 'grey-background';
      },
      processedListItems() {
      if (Array.isArray(this.listItems)) {
        return this.listItems;
      }
      return [this.listItems]; 
    },
  
    },
  };
  </script>
  
  <style scoped>
  .module-image {
  max-width: 100%; 
  height: auto; 
  margin: 10px 0; 
}

  .content-module {
    padding:10px  100px;
    justify-content: center;
    
  align-items: center; 
    text-align: center;
  
  }

  .blue-background {
    background-color: #3c98af;
    color: white;
  }
  
  .white-background {
    background-color: #FFFFFF;
    color: black;
  }
  .white-background h1 {
   font-size:20px;
  }
  
  .grey-background {
    background-color: rgb(43, 118, 128);
    color: rgb(255, 255, 255);
 
    text-align: center;
  }
  .grey-background h1 {
   font-size:30px;
  
  }
  .grey-background p {
   font-size:28px;
  
  }
  .content-module h2, .content-module p {
    margin: 10px ;
   
  }
  
  .content-module ul {
    list-style: inside;
    padding: 0;
  
  }
  
  .content-module ul li {
  
    margin: 5px 0px;
  }
 

  .content-module ul {
  list-style: none; /* 隐藏默认的列表项目符号 */
  padding-left: 0; /* 移除默认的填充 */
}

.content-module ul li {
  position: relative; /* 为伪元素定位做准备 */
  padding-left: 20px; /* 为自定义项目符号留出空间 */
  text-align: left;
}

.content-module ul li::before {
  content: ''; 
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px; 
  height: 10px; 
  /* border-radius: 50%; */
  background-color: orange; 
}










  @media (max-width: 1100px){

    .content-module {
    padding:10px;
 font-size: 2%;
  
  }

  .content-module ul li {
  
  margin: 5px 25px;
}

  }
  .custom-line {
    border: 1px solid #31a8cc; /* 深绿色 */
    margin: 10px 0; /* 根据需要调整边距 */
    position: relative;
    height: 2px; /* 线条的基本高度 */
  }


  .custom-line::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 30%; /* 前30% */
    height: 4px; /* 加粗的高度 */
    background-color: #31a8cc; /* 与线条相同的颜色 */
  }



  </style>
  