// store/index.js
import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      isLoggedIn: false,
      userId: null,
    };
  },
  mutations: {
    setLoginState(state, payload) {
      state.isLoggedIn = payload.isLoggedIn;
      state.userId = payload.userId;
    },
  },
});
