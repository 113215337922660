import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'ionicons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import store from './store';


// 添加 FontAwesome 图标到库
library.add(faCoffee, faSpinner);
library.add(faUserSecret);
// 创建 Vue 应用实例
const app = createApp(App);
// 使用 BootstrapVue 插件
app.use(store)
// 将 axios 添加为全局属性
app.config.globalProperties.$axios = axios;

// 注册 FontAwesomeIcon 组件
app.component('font-awesome-icon', FontAwesomeIcon);

// 使用路由器
app.use(router);

// 挂载 Vue 应用实例
app.mount('#app');


