<template>
    <div v-if="showButton" 
         class="floating-button" 
         :style="{ top: position.y + 'px', left: position.x + 'px' }"
         @mousedown="startDrag"
         @mouseup="stopDrag">
      <button @click="redirectToLink">고객 서비스</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showButton: true,
        isDragging: false,
        startPosition: { x: 0, y: 0 },
        position: { x: 10, y: 600 }, // 初始位置
      };
    },
    methods: {
      redirectToLink() {
        // 在这里定义点击按钮后的跳转行为
        window.location.href = 'https://lin.ee/lHolh2r';
      },
      startDrag(event) {
        this.isDragging = true;
        this.startPosition = {
          x: event.clientX - this.position.x,
          y: event.clientY - this.position.y,
        };
  
        document.addEventListener('mousemove', this.handleDrag);
      },
      stopDrag() {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.handleDrag);
      },
      handleDrag(event) {
        if (this.isDragging) {
          this.position.x = event.clientX - this.startPosition.x;
          this.position.y = event.clientY - this.startPosition.y;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .floating-button {
    position: fixed;
    z-index: 1000;
  }
  
  .floating-button button {
    /* 样式可以根据需要进行自定义 */
    padding: 10px;
    background-color: #45ecec;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  </style>
  