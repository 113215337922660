<template>
  <div>
   
    <MobileUI v-if="isMobile" />
    <DesktopUI v-else />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import MobileUI from './components/PonzUI.vue';  
import DesktopUI from './components/PonzUIpc.vue'; 


export default {
  components: {
    MobileUI,
    DesktopUI
  },
  setup() {
    const isMobile = ref(window.innerWidth < 1030); 

    // 检测屏幕尺寸的函数
    function checkScreenSize() {
      isMobile.value = window.innerWidth < 1030;
    }

    onMounted(() => {
      window.addEventListener('resize', checkScreenSize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenSize);
    });

    return {
      isMobile
    };
  }
};
</script>






<style>




.left-text:hover {
  background-color: #f0f0f0; /* 鼠标悬停时的背景颜色 */
  color: #b1b1b1; /* 鼠标悬停时的文字颜色 */
}

.left-text:hover .gfre {
  transform: scale(1.1); /* 鼠标悬停时图片放大 110% */
  filter: brightness(1.2);
  transition: filter 0.3s ease, transform 0.3s ease;
}
.right-text:hover {
  background-color: #f0f0f0; /* 鼠标悬停时的背景颜色 */
  color: #b1b1b1; /* 鼠标悬停时的文字颜色 */
}

.right-text:hover .gfre {
  transform: scale(1.1); /* 鼠标悬停时图片放大 110% */
  filter: brightness(1.2);
  transition: filter 0.3s ease, transform 0.3s ease;
}


.tit{
  color: #1b1b1b;
  font-size: 24px; /* 调整标题的字体大小 */
  text-align: center; /* 标题居中 */
}
.tit1{
  color: #ffffff;
  font-size: 24px; /* 调整标题的字体大小 */
  text-align: center; /* 标题居中 */
}
.tit2{
  color: #000000;
  font-size: 18px; /* 调整标题的字体大小 */
  text-align: center; /* 标题居中 */
}
.p1{
  color: #1f1e1e;
  font-size: 16px; 
  text-align: center;
  margin: 0 10px;
}
.p2{
  color: #504f4f;
  font-size: 12px; 
  text-align: center;
  margin: 0 10px;
}
.p3{
  color: #504f4f;
  font-size: 12px; 
  /* text-align: center; */
  margin: 0 10px;
  margin-left: 20px;
}

.home-page {
  /* 首页样式 */
  position: relative;
  
  
  align-items: center;
  height: auto;

}


.text-overlay {
background-color: #ffffff; /* 文本的背景颜色 */
color: rgb(255, 255, 255); /* 文本颜色，确保与背景色对比度足够 */
padding: 10px; /* 文本周围的空间 */

margin: 0 10px;
padding-top: 10px; /* 上内边距是10像素 */
padding-right: 10px; /* 右内边距是15像素 */
padding-bottom: 10px; /* 下内边距是5像素 */
padding-left: 30px; /* 左内边距是20像素 */
}


.gfre{
width: 90%; /* 图片宽度将根据父容器的宽度自动调整 */
height: auto;
display: block; /* 让图片水平居中显示 */
margin: 0 auto; /* 设置左右外边距为 auto，实现水平居中 */
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.gfre1 {
width: 66%;
height: auto;
display: block; /* 让图片水平居中显示 */
margin: 0 auto; /* 设置左右外边距为 auto，实现水平居中 */
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}


.gfre2 {
width: 100%;
height: auto;
display: block; /* 让图片水平居中显示 */
margin: 0 auto; /* 设置左右外边距为 auto，实现水平居中 */

}


.logo-f{
  width: 10%;
  height: auto;
  display: block; /* 让图片水平居中显示 */
  margin: 0 auto; /* 设置左右外边距为 auto，实现水平居中 */
}
 .tit111{
    color: #1b1b1b;
    font-size: 24px; /* 调整标题的字体大小 */
    text-align: center; /* 标题居中 */
  }
.p2{
    color: #504f4f;
    font-size: 12px; 
    text-align: center;
    margin: 0 10px;
  }
.dark1{
  
  font-size: 50px;
}
.dark2{
  
  font-size: 20px;
}

html, body {
  height: 100%; 
  margin: 0%; 
  padding: 0%;
  
  /* overflow-x: hidden; */

  /* max-width: 100vw; */
}

#app {
  display: flex;
  flex-direction: column;
  min-height: auto; 
}





/*顶部任务栏 */
.top-bar {
  display: flex;
  justify-content: flex-end; 
  /* justify-content: space-between;  */
  align-items: center; /* 垂直居中对齐 */
  
 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
 
background-color: white;

   position: relative;
   width: 100vw;
   height: 60px;
   
 }
 


.logo-link {
  margin: auto;
  display: flex;
  justify-content: center; 
  align-items: center; 
 
}

.logo {
 
  display: block; 
  max-width: 150px;  
  max-height: 75px;  
  height: auto;   
  right: 3%;
  position: absolute;
  top: -10px;
}


 /* .menu-button {

  display: flex; 
 
  height: auto;        
   justify-content: flex-end; 
  background: none;
  border: none;
  color: rgb(0, 0, 0); 
  font-size: 30px; 
  cursor: pointer;
  transition: color 0.3s ease; 
  z-index: 3000;
}

.menu-button::after {
  content: '';
  display: block;
  width: 10px; 
  margin-right: 10px;

  visibility: hidden; 
  
} */




.no-scroll {
  overflow: hidden;
  height: 100%;
}




 /* 弹出式侧边导航栏样式 */
 .side-nav {
  display: flex;
  flex-direction: column; /* 以列布局显示子元素 */
  align-items: flex-start; /* 子元素靠左对齐 */

   position: fixed;
   top: 0;
   /* left: -100%; */
   right: -100%; 
   width: 300px; /* 占屏幕的三分之二 */
   height: 100%;
   background-color: white;
   transition: right 0.3s ease; 
   box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
   border-right: 2px solid #adadad; 
   z-index: 2000;
   overflow-y: auto;
 }
 .side-nav.open {
   right: 0; 
   /* left: 0; */
 }
 
 

 /* 导航链接样式 */
 .nav-link {
  font-size: 1px;
  display: block;
  color: #000000;
  padding: 10px 20px; 
  text-decoration: none;
  width: 100%; 
  box-sizing: border-box;
  margin: 0; 
  transition: color 0.3s ease;
  /* border-bottom: 1px solid #cccccc;  */

   transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
  transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
  
 }


 .nav-link:hover {
  color: #5e5e5e; 
  /* font-size: larger; */
  transition:color 0.6s ease; /* 添加平滑的过渡效果 */
  transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */

}


 /* 导航链接样式 */
 .nav-link1 {

  
 
display: block;
color: #000000;
padding: 10px 20px; 
text-decoration: none;
width: 100%; 
box-sizing: border-box;
margin: 0; 
transition: color 0.3s ease;

/* border-bottom: 1px solid #cccccc;  */

transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */

}


.nav-link1:hover {
  color: #9e9d9d; 
  /* font-size: larger; */
  transition:color 0.6s ease; /* 添加平滑的过渡效果 */

cursor: pointer;

}
















 .nav-title {

  width: 100%;
 
}
.nav-title1 {
  cursor: pointer;
  width: 100%;
 
}

.nav-title2 {
  background: none;  /* 移除背景 */
  border: none;      /* 移除边框 */
  cursor: pointer;   /* 添加鼠标指针样式 */
  /* padding: 5px;       */
  
}









/*底部任务栏*/ 
.bottom-bar {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff; /* 底部任务栏背景颜色 */
   color: rgb(2, 2, 2); /* 底部任务栏文字颜色 */
   text-align: center; /* 文字居中对齐 */

   padding: 10px; /* 内部填充 */
   /* position: absolute;

   position: fixed; */
   /* bottom: 0;
   left: 0; */
   width: 100%;
   
 }
 .footer-link {
   color: rgb(0, 0, 0); /* 超链接文字颜色 */
   text-decoration: none; /* 去除下划线 */
   margin: 0 10px; /* 两侧外边距 */
   font-weight: bold; /* 字体加粗 */
   padding: 5px 0; /* 增加垂直内边距 */
   line-height: 8; /* 调整行高 */
   font-size: 13px; /* 字体大小 */
 }
 









 

.nav-links-container {
  display: flex;
  flex-direction: row; 
  /* align-items: center;  */
  flex-wrap: wrap;
  margin: 20% 0;
  
}




@media (max-width: 800px) {
  .home-link {
  text-decoration: underline; /* 添加下划线 */
  color: #ccc; /* 设置文字颜色为浅灰色 */
  font-size: 16px; /* 根据需要设置字体大小 */
  transition: color 0.3s; /* 平滑过渡效果 */
  margin-left: 20px;
}
  .nav-link{
    font-size: 15px;
    font-weight: bold;
  } 
  
  
  .nav-title {
    font-size: 30px; 
  }

  .nav-links-container {
    flex-direction: row; 
    /* flex-wrap: wrap;  */
  }

 
}

/* .dark-theme {
  background-color: black;
  color: white;
}

.dark-theme .TranslateButton,
.dark-theme .top-bar,
.dark-theme .tit2,
.dark-theme .tit111,
.dark-theme .p2,
.dark-theme .p1,
.dark-theme .tit1,
.dark-theme .tit,
.dark-theme .box-shadow,
.dark-theme .menu-button,
.dark-theme .side-nav,
.dark-theme .bottom-bar,
.dark-theme .top-bar,
.dark-theme .nav-link,
.dark-theme .nav-title,
.dark-theme .nav-title1,
.dark-theme .nav-title2,
.dark-theme .footer-link {
  background-color: black;
  color: white; 
  box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5); 
} */









.text-container111 {
  display: flex; 
  justify-content: space-between; /* 两侧的内容分布在容器的两端 */
  width: 100%;
}


.left-text{
  width: 50%;
  margin: 0 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  
}
.right-text {
  width: 50%; 
  margin: 0 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}





.table-container {
  display: table;
  width: 100%;
  border-collapse: collapse;
  
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 10px;
  border: 1px solid #ccc;
}


.table-cell.header {
  background-color: #016a84;
  color: white;
}










.video-container {
    position: relative;
    width: 80%; /* 容器宽度，可以调整 */
    padding-top: 50%; /* 16:9比例的顶部内边距 (9 / 16 = 0.5625) */
    overflow: hidden;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.home-link {
  text-decoration: underline; /* 添加下划线 */
  color: #ccc; /* 设置文字颜色为浅灰色 */
  font-size: 16px; /* 根据需要设置字体大小 */
  transition: color 0.3s; /* 平滑过渡效果 */
  margin-left: 230px;
}

.home-link:hover {
  color: #bbb; /* 鼠标悬停时文字颜色变为稍深的灰色 */
  text-decoration: none; /* 鼠标悬停时移除下划线 */
}
@media (max-width: 800px){
  .home-link {
  text-decoration: underline; /* 添加下划线 */
  color: #ccc; /* 设置文字颜色为浅灰色 */
  font-size: 16px; /* 根据需要设置字体大小 */
  transition: color 0.3s; /* 平滑过渡效果 */
  margin-left: 10px;
}

.home-link:hover {
  color: #bbb; /* 鼠标悬停时文字颜色变为稍深的灰色 */
  text-decoration: none; /* 鼠标悬停时移除下划线 */
}



}


</style>