

<script>
import TimeData1 from './TimeData1.vue';

import image2 from  './imagecom/image2.vue';

import image5 from  './imagecom/image5.vue';
import TextComS from  './textcom/TextComS.vue';
import VideoPlayer from './videos/VideoS.vue';

export default {
name: 'App',
components: {
 
  image2,
  
  image5,
  TimeData1,
  VideoPlayer,
  TextComS
 
},data() {
return {
  imageSrc: require('@/components/imagecom/pip1.png'),
  imageSrc1: require('@/components/imagecom/or1.png'),
  imageSrc2: require('@/components/imagecom/rs2.jpg'),

  imageSrc3: require('@/components/imagecom/image2.jpg'),
  imageSrc4: require('@/components/imagecom/image3.jpg'),
  imageSrc5: require('@/components/imagecom/image4.jpg'),
  imageSrc6: require('@/components/imagecom/image5.jpg'),
  imageSrc7: require('@/components/imagecom/image6.jpg'),
  imageSrc8: require('@/components/imagecom/image7.jpg'),
  imageSrc9: require('@/components/imagecom/image8.jpg'),
 
};
}
}

</script>

<template>
 
 

    <TimeData1 class="TimeData"></TimeData1>
    
    <image5 :image-src="imageSrc" 
    >
  </image5>
  <TextComS type="blue"  title="KM technology incice는 Chat GPT와 인공 코딩을 성공적으로 결합한 한국의 유명한 투자 자문 및 자산 관리 회사입니다."></TextComS>
  <TextComS type="white" title="No. 2" description="1억 달러 미만의 발표된 거래에서 미국 M&A 자문"></TextComS>
<TextComS type="white" title="No. 1" description="발표된 거래 수 기준 은행 및 저축소에서 미국 M&A 자문 1위"></TextComS>
<TextComS type="white" title="No. 2" description="미국 협상된 미니시팔 거래의 시니어 언더라이터"></TextComS>
<TextComS type="white" title="No. 2" description="커버리지 하에 있는 총 회사 기준 소형 및 중형 캡 부문의 리서치 플랫폼"></TextComS>
<VideoPlayer />
<br>

<div style="padding: 0 10px; background-color: rgb(233, 233, 233); text-align: center; ">
  <br>
  <h2>뉴스 & 인사이트</h2>
<br>
<hr style="color: #f8803b; width: 60%; height: 5px; background-color: #f8803b;">
<br>
<p>KM technology incice 주식 판매 및 거래 팀은 Rich Steiner 및 Ashley Teti의 추가로 성장합니다</p>
<br>
  <h3>2월 14일 / 읽기</h3>
<br>
<hr style="color: #f8803b; width: 60%; height: 5px; background-color: #f8803b;">
<br>
<p>KM technology incice 주식 자본 시장 팀은 Paul Robinson의 추가로 강화됩니다</p>
<br>
  <h3>2월 5일 / 읽기</h3>
<br>
<hr style="color: #f8803b; width: 60%; height: 5px; background-color: #f8803b;">
<br>
<p>KM technology incice 회사는 2023년 4분기 및 전체 연도 결과를 보고합니다; 주당 $1.00의 특별 배당금 및 분기별 배당금 $0.60을 선언합니다</p>
<br>
  <h3>2월 2일 / 읽기</h3>
<br>
<hr style="color: #f8803b; width: 60%; height: 5px; background-color: #f8803b;">
<br>
<p>KM technology incice는 Kate Clune을 최고 재무 책임자로 임명합니다</p>
<br>


</div>
<image2 :image-src="imageSrc1">
  </image2>










  <TextComS
type="grey"
title="진행 중인 파트너십"
description5="우리는 다양한 팀이 독특한 배경, 기술 및 경험을 가짐으로써 더 혁신적인 솔루션을 제공한다고 믿습니다."
></TextComS>

<image2 :image-src="imageSrc8" ></image2>

<div style="text-align: center; margin: 20px 10px ; padding: 20px 0;">
<h2>우리의 약속</h2>
<p>우리는 자본과 기회를 연결하여 가치를 창출하고 우리가 살고, 일하며, 번영하는 커뮤니티를 위해 더 나은 미래를 구축하기 위해 노력합니다.</p>
</div>

<hr style="color: #9e9e9e; width: 90%; height: 1px; background-color: #9e9e9e;">
<h2 style="text-align: center;">우리가 하는 일</h2>




  <div style="text-align: left; margin: 0 20px;padding: 30px 20px;background-color:rgb(236, 236, 236); ">
<router-link to="/JeF1">투자 은행</router-link>
</div>  

<hr style="color: #9e9e9e; width: 90%; height: 5px; background-color: #9e9e9e;">




<div style="text-align: left; margin: 0 20px;padding: 30px 20px;background-color:rgb(236, 236, 236); ">

<router-link to="/JeF2">공공 재정</router-link>
</div><hr style="color: #9e9e9e; width: 90%; height: 5px; background-color: #9e9e9e;">
<div style="text-align: left; margin: 0 20px;padding: 30px 20px;background-color:rgb(236, 236, 236); ">

<router-link to="/JeF3">연구</router-link>
</div><hr style="color: #9e9e9e; width: 90%; height: 5px; background-color: #9e9e9e;">
<div style="text-align: left; margin: 0 20px;padding: 30px 20px;background-color:rgb(236, 236, 236); ">

<router-link to="/JeF4">고정 수입</router-link>
</div><hr style="color: #9e9e9e; width: 90%; height: 5px; background-color: #9e9e9e;">
<div style="text-align: left; margin: 0 20px;padding: 30px 20px;background-color:rgb(236, 236, 236); ">

<router-link to="/JeF5">대체 자산 관리</router-link>
</div><hr style="color: #9e9e9e; width: 90%; height: 5px; background-color: #9e9e9e;">
<div style="text-align: left; margin: 0 20px;padding: 30px 20px;background-color:rgb(236, 236, 236); ">

<router-link to="/JeF6">주식</router-link>
</div><hr style="color: #9e9e9e; width: 90%; height: 5px; background-color: #9e9e9e;">


  


 
</template>


<style scoped>
.orger:hover{
background-color: rgb(240, 152, 53);

}
</style>





  
  












